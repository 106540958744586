import { Col, Row } from "antd";
import "./MissingData.scss";
import ReportHeader from "../00-Header/ReportHeader";
import { IMissingData } from "./IMissingData";
import dayjs from "dayjs";

const MissingData = ({
  templateData,
  translations,
  missingData,
}: IMissingData) => (
  <div id="missingData" contentEditable className="pagebreak">
    <ReportHeader
      confidential={translations.confidential}
      programName={templateData.programName}
      date={`Up to ${dayjs().format("DD/MM/YYYY")}`}
      section="Missing Data"
      page={templateData.page}
    />
    <Row align="middle">
      <Col>
        <main className="missingData">
          <p className="missingData-intro">
            If the data doesn't appear updated, make sure to run the procedures
            from the left panel.
          </p>
          <section className="missingData-content">
            <div>
              <h1>
                Missing Cia Portfolio Valuations:{" "}
                <span>
                  ({missingData.missingCiaPortfolioValuations.length})
                </span>
              </h1>
              {missingData.missingCiaPortfolioValuations.map(
                (comment: string, index: number) => (
                  <div key={index}>
                    <p>{comment}</p>
                  </div>
                )
              )}
            </div>
            <div>
              <h1>
                Missing Fund Valuations:{" "}
                <span>({missingData.missingFundOna.length})</span>
              </h1>
              {missingData.missingFundOna.map(
                (comment: string, index: number) => (
                  <div key={index}>
                    <p>{comment}</p>
                  </div>
                )
              )}
            </div>
            <div>
              <h1>
                Missing comments:{" "}
                <span>({missingData.missingCiaComments.length})</span>
              </h1>
              {missingData.missingCiaComments.map(
                (comment: string, index: number) => (
                  <div key={index}>
                    <p>{comment}</p>
                  </div>
                )
              )}
            </div>
          </section>
        </main>
      </Col>
    </Row>
  </div>
);

export default MissingData;
