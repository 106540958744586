import { AxiosResponse } from 'axios';

import API from './api';

import { IAPI } from './IAPI';

import { DirectForm } from '../Models/DirectForm';

const FormAPI = ({ baseUrl, getAuthToken, showServiceFeedbackModal }: IAPI) => {
    const api = API(baseUrl!, getAuthToken);

    const getPeips = () => {
		const serviceUrl = '/masters-api/Peips';
		return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
    };

    const getSections = (isFoF: boolean) => {
		return new Promise((resolve) => {
			if(isFoF){
				resolve([
					{
						label: "Cover",
						id: 'cover'
					},{
						label: "Index",
						id: 'index'
					},{
						label: "At a glance",
						id: 'atAGlance'
					},{
						label: "Main Figures",
						id: 'fundFigures'
					},{
						label: "Portfolio investments in detail",
						id: 'portfolioInvestments'
					},{
						label: "Underlying Funds",
						id: 'underlyingFunds'
					},{
						label: "Portfolio diversification",
						id: 'portfolioDiversification'
					},{
						label: "New investments and realizations",
						id: 'newInvestments'
					},{
						label: "New Funds",
						id: 'newFunds'
					},{
						label: "Portfolio companies in detail",
						id: 'portfolioCompanies'
					},{
						label: "Portfolio funds in detail",
						id: 'portfolioFunds'
					},{
						label: "Co-Investments Table",
						id: 'coinvestmentsTable'
					},{
						label: "Co-Investments Summary",
						id: 'coinvestmentsSummary'
					},{
						label: "Full portfolio breakdown",
						id: 'portfolioBreakdown'
					},{
						label: "Portfolio companies list",
						id: 'companiesList'
					},{
						label: "Fund description",
						id: 'fundDescription'
					},{
						label: "Glossary",
						id: 'glossary'
					}
			])
			}else{
				resolve([
				{
					label: "Cover",
					id: 'cover'
				},{
					label: "Index",
					id: 'index'
				},{
					label: "At a glance",
					id: 'atAGlance'
				}, {
					label: "Fund Figures",
					id: 'fundFigures'
				}, {
					label: 'Fund Evolution',
					id: 'fundEvolution'
				}, {
					label: 'Fund Charts',
					id: 'fundCharts'
				}, {
					label: 'Portfolio Performance',
					id: 'portfolioPerformance'
				}, {
					label: 'CoinvestDetail',
					id: 'coinvestDetail'
				}, {
					label: 'CoinvestCover',
					id: 'coinvestCover'
				}, {
					label: 'CoinvestAcquisition',
					id: 'coinvestAcquisition'
				}, {
					label: 'Glossary',
					id: 'glossary'
				}
			]);
			}
		});
    };

    const getFundsByPeip = (idPeip: number) => {
		const serviceUrl = `/funds-api/Fund/GetFundsByPeip/${idPeip}`;
		return api.get(serviceUrl)
		.then((response: AxiosResponse) => response.data)
		.catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
    };

	const send = (data: DirectForm, isFoF: boolean) => {
		const serviceUrl =  isFoF ? '/reporting-api/FoFReport/GetReportData'  : '/reporting-api/DirectReport/GetReportData';
		
		return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
	};

	const checkIfFofReportIsComplete = (idPeip: number, date: string) => {
		const serviceUrl = `/reporting-api/Quarterly/ValidateIfReportIsReady?IdPeip=${idPeip}&Date=${date}`;

		return api.get(serviceUrl)
			.then((response: AxiosResponse) => response.data)
			.catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
	}

    return {
        getPeips,
        getSections,
        getFundsByPeip,
		send,
		checkIfFofReportIsComplete
    };
};

export default FormAPI;