import { Button, Checkbox, Col, Input, Row, Select, Skeleton, Switch } from "antd";

import Preview from "../Preview/Preview";

import FormAPI from "../../api/FormAPI";

import useForm from "../../hooks/useForm";

import { IReportGenerator } from "./IReportGenerator";
import { jsonData } from "../../assets/data";
import { jsonDataDirect } from "../../assets/dataDirect";

const ReportGenerator = ({ baseUrl, getAuthToken, showServiceFeedbackModal }: IReportGenerator) => {
	const formAPI = FormAPI({
		baseUrl, getAuthToken, showServiceFeedbackModal
	});

	const {
		loadingFunds,
		loadingData,
		qualitasFunds,
		selectedQualitasFund,
		sections,
		selectedSections,
		funds,
		selectedFunds,
		year,
		selectedQuarter,
		selectedLanguage,
		reportData,

		handleChange,
		handleMultiselectChange,
		handleChangeSelectedSections,
		handleChangeSelectedFunds,
		sendData,
		isFoF,
		reportName,
		pageNumbers,
		formatNumber,
		fofMissingData,
		showFofMissingDataPage, 
		setShowFofMissingDataPage
	} = useForm({ api: formAPI});

	return (
		<div className="report-generator">
			<Row justify="center" align="top" dir="row">
				<Col span="8">
					<Row className="title" justify="start" align="middle">
						<h3>Generate a new Report</h3>
					</Row>
					<Row className="form-row" gutter={[16, 16]}>
						<Col className="form-element" span="24">
							<label>Qualitas Fund Program</label>
							<Select
								onChange={(e) => handleChange('Qualitas Fund')(e)}
								options={qualitasFunds}
								placeholder="Select a Program"
								value={selectedQualitasFund}
							/>
						</Col>
					</Row>
					<Row className="form-row" gutter={[16, 16]}>
						<Col className="form-element" span="24">
							<label>Sections</label>
							<Select
								mode="multiple"
								value={selectedSections}
								maxTagCount={1}
								onChange={handleMultiselectChange(selectedSections, sections, handleChangeSelectedSections)}
								options={sections}
								disabled={!selectedQualitasFund}
							/>
						</Col>
					</Row>
					<Row className="form-row" gutter={[16, 16]}>
						<Col className="form-element" span="24">
							<label>Funds</label>
							<Select
								mode="multiple"
								value={selectedFunds}
								maxTagCount={1}
								loading={loadingFunds}
								onChange={handleMultiselectChange(selectedFunds, funds, handleChangeSelectedFunds)}
								options={funds}
								disabled={isFoF || !selectedQualitasFund}
							/>
						</Col>
					</Row>
					<Row className="form-row" gutter={[16, 16]}>
						<Col className="form-element" span="8">
							<label>Year</label>
							<Input value={year} onChange={(e) => handleChange('Year')(e.target.value)}/>
						</Col>
						<Col className="form-element" span="8">
							<label>Term</label>
							<Select
								defaultValue={selectedQuarter}
								onChange={handleChange('Quarter')}
								options={[
									{
										value: "03-31",
										label: "Q1"
									}, {
										value: "06-30",
										label: "Q2"
									}, {
										value: "09-30",
										label: "Q3"
									}, {
										value: "12-31",
										label: "Q4"
									}
								]}
							/>
						</Col>
						<Col className="form-element" span="8">
							<label>Language</label>
							<Select
								defaultValue={selectedLanguage}
								onChange={handleChange('Language')}
								options={[
									{ value: "en", label: "English" },
									{ value: "es", label: "Spanish" },
								]}
							/>
						</Col>
					</Row>
					<div className="form-bottom-btns">
						<Button 
							type="primary" 
							className="generate-data-btn" 
							onClick={sendData} 
							disabled={
								!selectedQualitasFund 
								|| !selectedSections 
								|| !selectedFunds 
								|| !year 
								|| !selectedQuarter 
								|| !selectedLanguage
							}
							loading={loadingData}>
								Generate data
						</Button>
						{isFoF && 
							<Checkbox
								checked={showFofMissingDataPage}
								onChange={(e) => setShowFofMissingDataPage(e.target.checked)}
								disabled={loadingData}
							>
								Show missing data page
							</Checkbox>	
						}
					</div>				
				</Col>
				<Col className="preview">
				 {(!reportData?.cover ) &&
						<div className="loading-skeleton">
							<Skeleton.Avatar active={loadingData} shape="square" style={{width: '210mm', height: '20mm'}} />
							<Skeleton.Avatar active={loadingData} shape="square" style={{width: '210mm', height: '270mm'}} /> 
						</div>
					 } 
 					 {reportData?.cover
						&& <Preview
							isFoF={isFoF}
							selectedSections={selectedSections}
							reportData={reportData}
							selectedLanguage={selectedLanguage}
							reportName={reportName}
							pageNumbers={pageNumbers}
							formatNumber={formatNumber}
							showFofMissingDataPage={showFofMissingDataPage}
							fofMissingData={fofMissingData}
						/>
					}   
					{/*  <Preview
						selectedSections={selectedSections}
						reportData={isFoF ? jsonData : jsonDataDirect}
						selectedLanguage={selectedLanguage}
						isFoF={isFoF}
						reportName={reportName}
					/> */}
				</Col>
			</Row>
		</div>
	);
};

export default ReportGenerator;