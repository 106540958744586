import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Label,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";

import { ICoinvestDetail } from "./ICoinvestDetail";

import "./CoinvestDetail.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";


const CoinvestDetail = ({ templateData, translations, formatNumber }: ICoinvestDetail) => {
  let page = templateData.page - 1;
  return (
  <>
    {templateData.coinvestDetail?.map((company: any) =>{ 
      page ++
      return (
      <div id="coinvestDetail" contentEditable className="pagebreak">
        <ReportHeader
          confidential={translations.confidential}
          programName={templateData.programName}
          date={`${translations[templateData.month]} ${templateData.year}`}
          section={translations.section}
          page={page}
        />
        <main className="coinvestDetail">
          <section className="coinvestDetail-content">
            <div className="coinvestDetail-logo-title">
              <p>{company.coinvestName}</p>
              <div className="coinvestDetail-logo-title-description">
                <p>
                  {company.coinvestActivity}
                  <span>
                    {translations.coinvestWith.replace(
                      "[coinvestWith]",
                      company.coinvestWith
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div className="coinvestDetail-headerInfo">
              <div className="coinvestDetail-headerInfo-item">
                <p>{translations.entryDate}</p>
                <p>{company.coinvestDate}</p>
              </div>
              <div className="coinvestDetail-headerInfo-item">
                <p>{translations.initialCommitment}</p>
                <p>€{formatNumber(company.coinvestCommitment, 1)}M</p>
              </div>
              <div className="coinvestDetail-headerInfo-item">
                <p>{translations.currency}</p>
                <p>{company.coinvestCurrency}</p>
              </div>
              <div className="coinvestDetail-headerInfo-item">
                <p>{translations.participationPercentage}</p>
                <p>{formatNumber(company.coinvestParticipation, 2)}%</p>
              </div>
              <div className="coinvestDetail-headerInfo-item">
                <p>{translations.country}</p>
                <p>{company.coinvestCountry}</p>
              </div>
              <div className="coinvestDetail-headerInfo-item">
                <p>{translations.sector}</p>
                <p>{company.coinvestSector}</p>
              </div>
              <div className="coinvestDetail-headerInfo-item item-grey">
                <p>{translations.currentMoC}</p>
                <p>{formatNumber(company.coinvestMoc, 2)} x</p>
              </div>
              <div className="coinvestDetail-headerInfo-item item-grey">
                <p>{translations.trimPerformance}</p>
                <p>{formatNumber(company.coinvestPerformance, 1)}%</p>
              </div>
              {company.logoUrl && 
              <div className="coinvestDetail-headerInfo-item">
                <img
                  className="coinvestDetail-logo"
                  src={company.logoUrl}
                  alt="logo"
                />
              </div>
              }
            </div>
            <div className="coinvestDetail-description-table">
              <div className="coinvestDetail-description">
                <p>{translations.description}</p>
                <div
                  className="coinvestDetail-description-html"
                  dangerouslySetInnerHTML={{ __html: company.coinvestDescrip1 }}
                />
                <br />
                <p>{translations.investmentThesis}</p>
                <div
                  className="coinvestDetail-description-html"
                  dangerouslySetInnerHTML={{ __html: company.coinvestDescrip3 }}
                />
                <br />
                <p>{translations.sustainableDevelopmentGoals}</p>
                <div className="coinvestDetail-ods" id="coinvestDetail-ods">
                  {company.odsNumbers
                    ? company.odsNumbers.map((number: number) => (
                        <>
                          <img
                            src={`https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/ods-images/${number}.jpg`}
                            alt="desarrollo"
                          />
                          <br />
                        </>
                      ))
                    : null}
                </div>
              </div>
              <div className="coinvestDetail-evolution">
                <p>
                  {translations.performanceEvolution.replace(
                    "[quarter]",
                    `${templateData.quarter} ${templateData.year}`
                  )}
                </p>
                <div className="coinvestDetail-evolution-legends">
                  <p>
                    <span />
                    {translations.incomes}
                  </p>
                  <p>
                    <span />
                    EBITDA
                  </p>
                </div>
                <Chart
                  palette={["#f0f0f0"]}
                  animation={{ enabled: false }}
                  size={{ height: 150, width: 240 }}
                  dataSource={company.evolutionChartData}
                  customizeLabel={() => ({
                    backgroundColor: "none",
                    font: { size: 9, color: "black" },
                  })}
                >
                  <CommonSeriesSettings
                    argumentField="date"
                    type="bar"
                    barWidth={50}
                  >
                    <Label
                      font={{ color: "black", size: "11px" }}
                      customizeText={(e) => {
                        const formattedValue = e.valueText.replace(".", ",");
                        return formattedValue;
                      }}
                      visible={true}
                    />
                  </CommonSeriesSettings>
                  <Series valueField="incomes" name={translations.incomes} />
                  <Series
                    type="scatter"
                    valueField="ebitda"
                    name="EBITDA"
                    color="#B3D615"
                    point={{ size: 8 }}
                  />
                  <ValueAxis
                    visible={false}
                    maxValueMargin={0.05}
                    label={{ visible: false }}
                    grid={{ visible: false }}
                    showZero={true}
                    tick={{ visible: false }}
                  />
                  <ArgumentAxis
                    grid={{ visible: false }}
                    tick={{ visible: false }}
                    label={{
                      font: { color: "black", size: 9, family: "Lato" },
                    }}
                  />
                  <Tooltip />
                  <Legend visible={false} />
                </Chart>
              </div>
            </div>
            <div className="coinvestDetail-table">
              <p>{translations.financialMetrics}</p>
              <table>
                <colgroup>
                  {company.coinvestTableData.length
                    ? company.coinvestTableData.map((element: any) => {
                        const width = element.width || "100px";
                        return <col style={{ width }} />;
                      })
                    : null}
                </colgroup>
                <thead>
                  <tr className="coinvestDetail-table-header">
                    {company.coinvestTableData
                      ? company.coinvestTableData.map((element: any) => (
                          <th>{element.header}</th>
                        ))
                      : null}
                  </tr>
                  <tr className="coinvestDetail-table-subheader">
                    {company.coinvestTableData
                      ? company.coinvestTableData.map((element: any) => (
                          <th>{element.subHeader}</th>
                        ))
                      : null}
                  </tr>
                </thead>
                <tbody id="table-body">
                  {company.coinvestTableData.length > 0
                    ? company.coinvestTableData[0].data.map(
                        (element: any, index: number) => (
                          <tr
                            className={
                              index === 0 || index === 2 || index === 5
                                ? "coinvestDetail-table-bold"
                                : "coinvestDetail-table-italic"
                            }
                          >
                            {company.coinvestTableData.map(
                              (columnElement: any, columnIndex: number) => (
                                <td>{formatNumber(columnElement.data[index], 1)}</td>
                              )
                            )}
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </table>
            </div>
            <div className="coinvestDetail-last-section">
              <div>
                <p className="coinvestDetail-compania">
                  {translations.companyUpdate}
                </p>
                <div
                  className="company-html"
                  dangerouslySetInnerHTML={{ __html: company.coinvestUpdates }}
                />
              </div>
            </div>
          </section>
        </main>
        <div style={{ pageBreakAfter: "always" }}></div>
      </div>
    )})}
  </>
)};

export default CoinvestDetail;
